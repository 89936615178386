import React from 'react';
import PropTypes from "prop-types"
import {Link} from "react-router-dom";


const Btn = (props) => {
    let className = "btn-light btn-hover-primary";
    if (props.variant === "outlined") {
        className = "btn-outline-white btn-hover-primary";
    } else if (props.variant === "filled") {
        className = "btn-primary btn-hover-secondary";
    }
    return (
        <React.Fragment>
            <Link to={process.env.PUBLIC_URL + props.route} className={`btn ${className} rounded-pill`}>
                {props.name}
            </Link>
        </React.Fragment>
    )
}

Btn.propTypes = {
    name: PropTypes.string,
    route: PropTypes.string,
    variant: PropTypes.string
}

export default Btn
