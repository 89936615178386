import React from 'react';
import {NavLink} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MobileNavMenu = () => {
    const { t } = useTranslation();
    // const alternativeLanguageTag = i18n.language.substring(0, 2) === 'en' ? 'it' : 'en';
    // const alternativeLanguage = t(`language_${alternativeLanguageTag}`).toLowerCase();

    return (
        <nav className="site-mobile-menu">
            <ul>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/"}><span className="menu-text">{t('homepage')}</span></NavLink>
                </li>
                {/* <li>
                    <NavLink to={process.env.PUBLIC_URL + "/marketplace"}><span className="menu-text">{t('marketplace')}</span></NavLink>
                </li> */}
                {/* TODO: fee structure */}
                {/* <li>
                    <NavLink to={process.env.PUBLIC_URL + "/fees"}><span className="menu-text">{t('fee_structure')}</span></NavLink>
                </li> */}
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/about"}><span className="menu-text">{t('about_us')}</span></NavLink>
                </li>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/faqs"}><span className="menu-text">{t('faqs')}</span></NavLink>
                </li>
                {/* <li>
                    <div onClick={() => i18n.changeLanguage(alternativeLanguageTag)}
                        className='mobile-menu-link'>
                        {t('switch_to', {language: alternativeLanguage})}
                    </div>
                </li> */}
            </ul>
        </nav>
    )
}

export default MobileNavMenu;
