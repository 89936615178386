import React from 'react'
import {NavLink} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NavBar = () => {
    const { t } = useTranslation();
    // TODO change approach if more language will be available
    // const alternativeLanguage = i18n.language === 'en' ? 'it' : 'en';
    return (
        <nav className="site-main-menu">
            <ul>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/"}><span className="menu-text">{t('homepage')}</span></NavLink>
                </li>
                {/* <li>
                    <NavLink to={process.env.PUBLIC_URL + "/marketplace"}><span className="menu-text">{t('marketplace')}</span></NavLink>
                </li> */}
                {/* TODO: fee structure */}
                {/* <li>
                    <NavLink to={process.env.PUBLIC_URL + "/fees"}><span className="menu-text">{t('fee_structure')}</span></NavLink>
                </li> */}
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/about"}><span className="menu-text">{t('about_us')}</span></NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/faqs"}><span className="menu-text">{t('faqs')}</span></NavLink>
                </li>
                {/* <li>
                    <div className='d-flex align-items-center navbar-link' 
                    onClick={() => {
                        i18n.changeLanguage(alternativeLanguage);
                    }}>
                        <i className='fa fa-globe'></i>
                        <p className='ms-1'>{alternativeLanguage.toUpperCase()}</p>
                    </div>
                </li> */}
            </ul>
        </nav>
    )
}

export default NavBar
