import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
//import BrandContainer from '../container/Brand/BrandContainer';'''
import FaqFullpage from "../container/Faq/FaqFullpage.js";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import { useTranslation } from "react-i18next";

const FAQs = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <SEO title={`DeepTree | ${t("faqs")}`}
        description={t('seo_faqs_description')} />
      <Header />
      <Breadcrumb
        image="images/bg/breadcrumb-bg-two.jpg"
        title={t("faqs_title")}
        content="Home"
        contentTwo="FAQs"
      />
      <FaqFullpage />

      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default FAQs;

/// <BrandContainer classOption="section-padding-bottom" />
