import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import translationEN from '../assets/strings/en.json';

// Initialize localization
function initializeLocalization() {
  i18n
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          translation: translationEN,
        },
        // it: {
        //   translation: translationIT,
        // },
      },
      lng: 'en', // Default language
      fallbackLng: 'en', // Fallback language in case the requested language is not available
      interpolation: {
        escapeValue: false, // React already escapes values
      },
    });
}

export default initializeLocalization;