import Accordion, {
  AccordionItem,
  AccordionTitle,
  AccordionContent,
} from "../../components/Accordion";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();
  return (
    <div className="faqs-full-section section bg-primary-blue pt-16">
      <div className="container">
        <div className="row">
          <div className="col mb-6" data-aos="fade-up">
            <div className="faq-content agency-accordion max-mb-n30">
              <Accordion openAtFirst={false}>
                <AccordionItem id="one">
                  <AccordionTitle id="one" isBig>
                    {t("faq_1_question")}
                  </AccordionTitle>
                  <AccordionContent id="one">
                    {t("faq_1_answer")}
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem id="two">
                  <AccordionTitle id="two" isBig>
                    {t("faq_2_question")}
                  </AccordionTitle>
                  <AccordionContent id="two">
                    {t("faq_2_answer")}
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem id="three">
                  <AccordionTitle id="three" isBig>
                    {t("faq_3_question")}
                  </AccordionTitle>
                  <AccordionContent id="three">
                    {t("faq_3_answer")}
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem id="four">
                  <AccordionTitle id="four" isBig>
                    {t("faq_4_question")}
                  </AccordionTitle>
                  <AccordionContent id="four">
                    {t("faq_4_answer")}
                  </AccordionContent>
                </AccordionItem>

                <AccordionItem id="five">
                  <AccordionTitle id="five" isBig>
                    {t("faq_5_question")}
                  </AccordionTitle>
                  <AccordionContent id="five">
                    {t("faq_5_answer")}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
