import {useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from './components/NavScrollTop';
import FAQs from "./pages/FAQs";
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const CompanyContact = lazy(() => import("./pages/CompanyContact"));
const Contact = lazy(() => import("./pages/Contact"));
const ComingSoon = lazy(() => import("./pages/ComingSoon"));

function App() {
  useEffect(() => {
    AOS.init({
        offset: 80,
        duration: 1000,
        once: true,
        easing: 'ease',
    });
    AOS.refresh();
    
  }, [])
  return (
      <Router>
        <NavScrollTop>
          <Suspense fallback={<div />}>
              <Routes>
                <Route path={`${process.env.PUBLIC_URL + "/"}`} element={<Home/>}/>
                {/* <Route path={`${process.env.PUBLIC_URL + "/marketplace"}`} element={<Marketplace/>} /> */}
                {/* TODO: fee structure */}
                {/* <Route path={`${process.env.PUBLIC_URL + "/fees"}`} element={<Home/>} /> */}
                <Route path={`${process.env.PUBLIC_URL + "/app"}`} element={<ComingSoon/>} />
                <Route path={`${process.env.PUBLIC_URL + "/about"}`} element={<About/>} />
                <Route path={`${process.env.PUBLIC_URL + "/faqs"}`} element={<FAQs/>} />
                <Route path={`${process.env.PUBLIC_URL + "/companies/:id"}`} element={<CompanyContact/>} />
                <Route path={`${process.env.PUBLIC_URL + "/contact"}`} element={<Contact/>} />
              </Routes>
            </Suspense>
        </NavScrollTop>
      </Router>
  );
}

export default App;
