import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer-bg-color">
      <div className="container">
        <div className="row">
          <div className="col">
            <p className="final-description">{t("footer_first_line")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
